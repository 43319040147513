import React from 'react';
import useBbshMember from '../../../utils/hooks/members/useBbshMember';
import MembersLayout from '../../../components/Members/Layout';
import Container from '../../../components/Container';
import { Link } from 'gatsby';
import Button from '../../../components/Button';
import Text from '../../../components/Text';

const MembersInactiveMembership = () => {
  const { member } = useBbshMember();
  return (
    <MembersLayout title="Inactive membership">
      <Container py={[3, 4]} maxWidth={480}>
        <Text fontSize={2} mb={3}>
          Sorry {member?.firstName}, your membership is currently inactive or
          expired. If you think this is a mistake, please contact us at{' '}
          <a href="mailto:bbsh.office@barbarabrennan.com">
            bbsh.office@barbarabrennan.com
          </a>
        </Text>
        <Link to="/members/account">
          <Button>Account settings</Button>
        </Link>
      </Container>
    </MembersLayout>
  );
};

export default MembersInactiveMembership;
